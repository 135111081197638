
html {
    scroll-behavior: smooth;
  }
* {
    margin: 0;
    padding: 0;
}

.card-overlay {
    background-color: #fed4d8;
  }

  /* url("/images/stickers.png") */
.jumbotron{
    background-image: linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,10)), ;
    background-size: cover;
    height: 100%;
}

.carousel-inner{
  width:100%;
  max-height: 700px !important;
}

body {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #777
}

h1 {
    font-size: 36px;
    color: #555;
    font-weight: bold;
}
.test {
    font-size: 36px;
    color: #555;
    font-weight: bold;
}

#team img {
    margin-top: -40pt;
}

#team i {
    font-size: 26px;
    color: #555
}

#team p {
    font-weight: 500;
} 

#team .card {
    border-radius: 0;
    box-shadow: 5px 5px 15px #e74c3c;
    transition: all 0.3s ease-in;
    -webkit-transition: all  0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
}

#team .card:hover {
    background: #e74c3c;
    color: #fff;
    border-radius: 5px;
    border: none;
    box-shadow: 5px 5px 10px #9E9E9E;
}

#team .card:hover h3, #team .card:hover i {
    color: #fff;
    
}

/* #grad1 {
    height: 100%;
    background-color: #333;
    background-image: linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url("mikee.jpg");
  } */

  
  

/* .test {
    width: 100%;
    height: 500px;
    background-color: #333;
    background: linear-gradient(to bottom right, rgba(0,0,0,.5), rgba(0,0,0,.5)), url(images/Mikee.jpg);
    background-size: cover;
  } */
  
  .table-responsive {
    display: table;
}

caption {
    font-size: 150%;
    background-color: purple;
    color: white;
    text-align: center;
}

#topheader .navbar-nav li > a {
	text-transform: capitalize;
	color: #333;
	transition: background-color .2s, color .2s;
	
	&:hover,
	&:focus {
		background-color: #333;
		color: #fff;
	}
}

#topheader .navbar-nav li.active > a {
	background-color: #333;
	color: #fff;
}

caption.anchor {
    display: block;position: relative;top: -250px;visibility: hidden;
}

